<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>Flight report</h1>
            <mdb-spinner v-if="isloading && !error" />
            <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
            <mdb-alert color="success" v-if="success">{{success}}</mdb-alert>
            <div v-if="!isloading">
              <mdb-row>
                <mdb-col md="6">
                  <mdb-card>
                    <mdb-card-header color="grey darken-3">General informations</mdb-card-header>
                    <mdb-card-body>
                      <mdb-tbl borderless sm>
                        <mdb-tbl-body>
                          <tr v-if="lastupdate">
                            <td>Last update</td>
                            <td>{{lastupdate.user.name}} (<strong>{{formatDateTime(lastupdate.created_at)}}</strong>)</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td>{{formatDate(segmentinfos.departure_datetime)}}</td>
                          </tr>
                          <tr>
                            <td>Flight num.</td>
                            <td>{{segmentinfos.flight.flightnum}}</td>
                          </tr>
                          <tr>
                            <td>Route</td>
                            <td>{{segmentinfos.departure_gateway.code}} <mdb-icon icon="plane" class="mx-2" />{{segmentinfos.arrival_gateway.code}}</td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Counter num" v-model="flightreport.counternum">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="A/C reg" v-model="flightreport.acreg">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Gate" v-model="flightreport.gate">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Station" v-model="flightreport.station">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input type="textarea" v-model="flightreport.notes" label="Notes" :rows="5"/>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </mdb-card-body>
                  </mdb-card>
                  <mdb-card class="mt-3">
                    <mdb-card-header color="unique-color">Gate pieces</mdb-card-header>
                    <mdb-card-body>
                      <mdb-tbl borderless sm>
                        <mdb-tbl-body>
                          <tr>
                            <td>
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Nb. Bags" type="number" v-model="flightreport.gatepiecesbags">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Nb. Wcmp" type="number" v-model="flightreport.gatepieceswcmp">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Nb. Wcbd" type="number" v-model="flightreport.gatepieceswcbd">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Nb. Strls" type="number" v-model="flightreport.gatepiecesstrls">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Nb. Crst" type="number" v-model="flightreport.gatepiecescrst">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
                <mdb-col md="6">
                  <mdb-card>
                    <mdb-card-header color="green darken-3">Departure informations</mdb-card-header>
                    <mdb-card-body>
                      <mdb-tbl borderless sm>
                        <mdb-tbl-body>
                          <tr>
                            <td colspan="2">
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-4" label="STD" placeholder="HH:MM" v-model="flightreport.ob_std">
                                    <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('ob_std')">Now</mdb-btn>
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td>ETD</td>
                            <td>{{formatDateTime(segmentinfos.departure_datetime)}}</td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-4" label="ATD" placeholder="HH:MM" v-model="flightreport.ob_atd">
                                    <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('ob_atd')">Now</mdb-btn>
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Pax count" type="number" v-model="flightreport.nbpaxes">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Infants count" type="number" v-model="flightreport.nbinfants">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </mdb-card-body>
                  </mdb-card>

                  <mdb-card class="mt-3">
                    <mdb-card-header color="blue darken-3">Staff</mdb-card-header>
                    <mdb-card-body>
                      <mdb-tbl borderless sm>
                        <mdb-tbl-body>
                          <tr>
                            <td>
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Supervisor" type="text" v-model="flightreport.boardingteamsupervisor">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Lead" type="text" v-model="flightreport.boardingteamlead">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Agent 1" type="text" v-model="flightreport.boardingteamagent1">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Agent 2" type="text" v-model="flightreport.boardingteamagent2">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mdb-row>
                                <mdb-col>
                                  <mdb-input class="mt-0 mb-0" label="Agent 3" type="text" v-model="flightreport.boardingteamagent3">
                                  </mdb-input>
                                </mdb-col>
                              </mdb-row>
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
              </mdb-row>
              <mdb-row class="pt-3">
                <mdb-col>
                  <h2>Passenger services / announcements</h2>
                </mdb-col>
              </mdb-row>
              <mdb-row class="pt-3 pb-5">
                <mdb-col md="6">
                  <mdb-card>
                    <mdb-card-header color="grey darken-3">Check-in</mdb-card-header>
                    <mdb-card-body>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Desk opened" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.desk_opened">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('desk_opened')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Desk closed" placeholder="HH:MM" v-model="flightreport.desk_closed">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('desk_closed')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Nb web checkin" type="number" v-model="flightreport.num_webcheckin">
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Go show" type="number" v-model="flightreport.go_show">
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="No show" type="number" v-model="flightreport.no_show">
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Total pieces/bags" type="number" v-model="flightreport.nb_bags">
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Total weight" type="number" v-model="flightreport.total_weight">
                            <span class="input-group-text md-addon" slot="append">Kg</span>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>

                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
                <mdb-col md="6">
                  <mdb-card>
                    <mdb-card-header color="grey darken-3">Gate</mdb-card-header>
                    <mdb-card-body>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Gate open" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.gate_open">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('gate_open')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Welcome message" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.welcome_msg">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('welcome_msg')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Gate Check Message (120+ Pax)" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.flight_over_120">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('flight_over_120')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="OK CSM" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.ok_csm">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('ok_csm')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Pre-boarding" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.pre_boarding">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('pre_boarding')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Zone 1 (row 27 to 14)" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.zone_1">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('zone_1')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Zone 2 (row 13 to 1)" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.zone_2">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('zone_2')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Final boarding call" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.final_boarding_call">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('final_boarding_call')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Last PAX" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.last_pax">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('last_pax')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Flight secured" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.flight_secured">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('flight_secured')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Door closed" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.door_closed">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('door_closed')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="Push back" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.push_back">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('push_back')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
              </mdb-row>
              <mdb-row class="pb-3">
                <mdb-col>
                  <mdb-card>
                    <mdb-card-header color="grey darken-3">Denied boarding</mdb-card-header>
                    <mdb-card-body>
                      <mdb-tbl v-if="deniedpax.length" borderless sm hover>
                        <mdb-tbl-head color="black" textWhite>
                          <tr>
                            <th>Pax</th>
                            <th>Reason</th>
                            <th>&nbsp;</th>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr v-for="(pax,index) in deniedpax" v-bind:key="index">
                            <td>{{pax.pax.firstname}} {{pax.pax.lastname}} | {{formatDate(pax.pax.birthdate)}} (Booking: {{pax.pax.bookingnumber}})</td>
                            <td>{{pax.reason}}<div v-if="pax.details"><br /><em>{{pax.details}}</em></div></td>
                            <td class="text-right">
                              <mdb-btn color="danger" size="sm" icon="trash" v-on:click="removeDeniedPax(pax.pax_id)"></mdb-btn>
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                      <div v-else>
                        <h3>No record found</h3>
                      </div>
                      <mdb-btn color="secondary" v-on:click="showAddDeniedPaxForm()">Add new pax</mdb-btn>
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
              </mdb-row>
              <mdb-row class="pt-3">
                <mdb-col>
                  <h2>Delays</h2>
                </mdb-col>
              </mdb-row>
              <mdb-row class="pb-4">
                <mdb-col md="7">
                  <mdb-card>
                    <mdb-card-header color="grey darken-3">Types of delays</mdb-card-header>
                    <mdb-card-body>
                      <mdb-tbl>
                        <mdb-tbl-head>
                          <tr>
                            <th>Code</th>
                            <th>Description</th>
                            <th>&nbsp;</th>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr v-for="(item,index) in selecteddelaycodes" v-bind:key="index">
                            <td>
                              <strong>{{ item.num }} {{ item.alpha }}</strong>
                              <br />
                              <h6>{{ item.originaltext }}</h6>
                              <em v-if="item.subtext">{{ item.subtext }}</em>
                              <div v-if="item.duration">
                                <span><strong>Duration</strong>: {{item.duration}}</span>
                              </div>
                            </td>
                            <td>{{item.description}}</td>
                            <td><mdb-btn size="sm" color="danger" icon="trash" v-on:click="removeDelayType(index)" /></td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                      <mdb-btn color="secondary" v-on:click="showAddDelayCodeModal()">Add new delay code</mdb-btn>
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
                <mdb-col md="5">
                  <mdb-card>
                    <mdb-card-header color="grey darken-3">Delay announcements</mdb-card-header>
                    <mdb-card-body>
                      <p>Delay announcements must be done every 30 minutes. Record time</p>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="0.5h" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.announcementdelay05">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('announcementdelay05')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="1h" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.announcementdelay10">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('announcementdelay10')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="1.5h" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.announcementdelay15">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('announcementdelay15')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="2h" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.announcementdelay20">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('announcementdelay20')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="2.5h" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.announcementdelay25">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('announcementdelay25')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="3h" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.announcementdelay30">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('announcementdelay30')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <mdb-input class="mt-0 mb-4" label="3.5h" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="flightreport.announcementdelay35">
                            <mdb-btn color="primary" size="md" group slot="append" v-on:click="setNow('announcementdelay35')">Now</mdb-btn>
                          </mdb-input>
                        </mdb-col>
                      </mdb-row>
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
              </mdb-row>
              <mdb-row class="mb-4" v-if="$store.state.accessmode === 'FULLACCESS' || new Date(segmentinfos.departure_datetime).getTime() >= new Date(new Date().setDate(new Date().getDate() - 1)).getTime()">
                <mdb-col md="12">
                  <mdb-btn class="container-fluid" size="lg" v-on:click="saveData(true)">Enregistrer</mdb-btn>
                </mdb-col>
              </mdb-row>
            </div>
          </mdb-container>
        </div>
      </main>
    </div>
    <mdb-modal :show="showAddDeniedModal" @close="showAddDeniedModal = false" size="lg" class="text-center" info>
      <mdb-modal-header center :close="false">
        <p class="heading">Add a denied pax</p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-alert color="danger" v-if="addDeniedPaxError">{{addDeniedPaxError}}</mdb-alert>
        <mdb-select
          color="primary"
          v-model="passengersAvailableToDeny"
          :search=true
          :searchPlaceholder="$t('searchengine.search')"
          label="Passenger"
          :visibleOptions=10
        />
          <mdb-select
            color="primary"
            v-model="deniedPaxReasons"
            label="Reason"
            :visibleOptions=10
          />
        <mdb-input v-model="currentotherpaxdeniedreason" label="Details" />
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" v-on:click="executeAddDeniedPax()">Add</mdb-btn>
        <mdb-btn color="danger" @click="showAddDeniedModal = false">Cancel</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="showAddDelayCode" @close="showAddDelayCode = false" size="lg" class="text-center" info>
      <mdb-modal-header center :close="false">
        <p class="heading">Add a delay code</p>
      </mdb-modal-header>
      <mdb-modal-body class="text-left">
        <mdb-alert color="danger" v-if="addDelayError">{{addDelayError}}</mdb-alert>
        <mdb-select
          color="primary"
          v-model="delaycodes"
          label="Delay code"
          :visibleOptions=10
          :search=true
          :searchPlaceholder="$t('searchengine.search')"
        />
        <p class="small">{{selecteddelaysubtext}}</p>
        <mdb-input class="mt-0 mb-4" label="Duration" ariaDescribedBy="button-addon2" placeholder="HH:MM" v-model="newdelayduration" />
        <mdb-input type="textarea" v-model="newdelaycodedescription" label="description" :rows="5"/>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" v-on:click="executeAddDelayCode()">Add</mdb-btn>
        <mdb-btn color="danger" @click="showAddDelayCode = false">Cancel</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbAlert,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbSpinner,
  mdbTbl,
  mdbTblBody,
  mdbTblHead,
  mdbInput,
  mdbBtn,
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbSelect,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'flightreport',
  data() {
    return {
      isloading: true,
      flightreport: {},
      segmentinfos: {},
      deniedpax: [],
      lastupdate: null,
      showAddDeniedModal: false,
      showAddDelayCode: false,
      delaycodes: [],
      newdelaycodedescription: '',
      newdelayduration: '',
      addDelayError: null,
      selecteddelaycodes: [],
      currentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      error: null,
      success: null,
      currentotherpaxdeniedreason: null,
      addDeniedPaxError: null,
      deniedPaxReasons: [
        {
          text: ' Late',
          value: 'Late',
          selected: false,
        },
        {
          text: 'Travel Documents',
          value: 'Travel Documents',
          selected: false,
        },
        {
          text: 'Unruly',
          value: 'Unruly',
          selected: false,
        },
        {
          text: 'OTHER',
          value: 'OTHER',
          selected: false,
        },
      ],
      passengersAvailableToDeny: [],
    };
  },
  computed: {
    selecteddelaysubtext() {
      for (let i = 0; i < this.delaycodes.length; i += 1) {
        if (this.delaycodes[i].selected) {
          return this.delaycodes[i].subtext;
        }
      }
      return '';
    },
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbAlert,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbSpinner,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbInput,
    mdbBtn,
    mdbIcon,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbSelect,
  },
  mounted() {
    this.fetchInfos();
  },
  methods: {
    fetchInfos() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/flight/get-report-infos-for-segment', { id: this.$route.params.id }).then((response) => {
        if (response.status === 'OK') {
          self.flightreport = response.data.report;
          self.segmentinfos = response.data.segment;
          self.deniedpax = response.data.deniedpax;
          self.delaycodes = response.data.availdelaycodes;
          self.lastupdate = response.data.lastupdateby;
          self.selecteddelaycodes = response.data.selecteddelaycodes;
          self.flightreport.arrivecan_certified_by_initial_uid = self.flightreport.arrivecan_certified_by_initial_uid !== null;
          self.flightreport.pcr_certified_by_initial_uid = self.flightreport.pcr_certified_by_initial_uid !== null;
        } else {
          self.error = response.msg;
        }
        self.isloading = false;
      });
    },
    formatDateTime(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    setNow(field) {
      const thedate = new Date();
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };
      this.flightreport[field] = thedate.toLocaleTimeString('en-CA', options);
      this.saveData();
    },
    updateFromDCS() {
      console.log('function todo');
      this.saveData();
    },
    showAddDeniedPaxForm(paxid = null) {
      this.showAddDeniedModal = true;
      this.addDeniedPaxError = null;
      this.currentotherpaxdeniedreason = null;
      this.passengersAvailableToDeny = [];
      for (let i = 0; i < this.deniedPaxReasons.length; i += 1) {
        this.deniedPaxReasons[i].selected = false;
      }
      for (let i = 0; i < this.segmentinfos.paxsegments.length; i += 1) {
        let isalreadydenied = false;
        for (let j = 0; j < this.deniedpax.length; j += 1) {
          if (this.deniedpax[j].pax_id === this.segmentinfos.paxsegments[i].pax_id) {
            isalreadydenied = true;
          }
        }
        if (!isalreadydenied) {
          const formateddate = this.formatDate(this.segmentinfos.paxsegments[i].pax.birthdate);
          this.passengersAvailableToDeny.push({
            text: `${this.segmentinfos.paxsegments[i].pax.firstname} ${this.segmentinfos.paxsegments[i].pax.lastname} | ${formateddate} (Booking: ${this.segmentinfos.paxsegments[i].pax.bookingnumber})`,
            value: this.segmentinfos.paxsegments[i].pax_id,
            selected: false,
          });
        }
      }
    },
    executeAddDeniedPax() {
      let canadd = true;
      let selectedpax = null;
      let paxinfos = {};
      for (let i = 0; i < this.passengersAvailableToDeny.length; i += 1) {
        if (this.passengersAvailableToDeny[i].selected) {
          selectedpax = this.passengersAvailableToDeny[i].value;
        }
      }
      if (!selectedpax) {
        canadd = false;
        this.addDeniedPaxError = 'Please choose a Passenger';
      } else {
        for (let i = 0; i < this.segmentinfos.paxsegments.length; i += 1) {
          if (this.segmentinfos.paxsegments[i].pax_id === selectedpax) {
            paxinfos = {
              firstname: this.segmentinfos.paxsegments[i].pax.firstname,
              lastname: this.segmentinfos.paxsegments[i].pax.lastname,
              birthdate: this.segmentinfos.paxsegments[i].pax.birthdate,
              bookingnumber: this.segmentinfos.paxsegments[i].pax.bookingnumber,
            };
          }
        }
      }

      let selectedreason = '';
      let moredetails = '';
      for (let i = 0; i < this.deniedPaxReasons.length; i += 1) {
        if (this.deniedPaxReasons[i].selected) {
          if (this.deniedPaxReasons[i].selected) {
            moredetails = this.currentotherpaxdeniedreason;
            selectedreason = this.deniedPaxReasons[i].value;
          }
        }
      }
      if (!selectedreason) {
        canadd = false;
        this.addDeniedPaxError = 'Please choose a reason';
      }
      if (canadd) {
        this.deniedpax.push({
          pax: paxinfos,
          pax_id: selectedpax,
          reason: selectedreason,
          details: moredetails,
        });
        this.showAddDeniedModal = false;
      }
      this.saveData();
    },
    removeDeniedPax(paxid) {
      const tmpdeniedpax = [];
      for (let j = 0; j < this.deniedpax.length; j += 1) {
        if (this.deniedpax[j].pax_id !== paxid) {
          tmpdeniedpax.push(this.deniedpax[j]);
        }
      }
      this.deniedpax = tmpdeniedpax;
      this.saveData();
    },
    showAddDelayCodeModal() {
      for (let i = 0; i < this.delaycodes.length; i += 1) {
        this.delaycodes[i].selected = false;
      }
      this.newdelaycodedescription = '';
      this.showAddDelayCode = true;
      this.addDelayError = null;
    },
    executeAddDelayCode() {
      let selecteddelay = null;
      for (let i = 0; i < this.delaycodes.length; i += 1) {
        if (this.delaycodes[i].selected) {
          selecteddelay = this.delaycodes[i];
        }
      }
      if (selecteddelay && this.newdelayduration) {
        this.selecteddelaycodes.push({
          num: selecteddelay.num,
          alpha: selecteddelay.alpha,
          originaltext: selecteddelay.originaltext,
          text: selecteddelay.text,
          subtext: selecteddelay.subtext,
          description: this.newdelaycodedescription,
          duration: this.newdelayduration,
        });
        this.addDelayError = null;
        this.showAddDelayCode = false;
        this.saveData();
      } else {
        this.addDelayError = 'Please choose a delay code and add a delay duration';
      }
    },
    removeDelayType(index) {
      const tmpdelays = [];
      for (let i = 0; i < this.selecteddelaycodes.length; i += 1) {
        if (i !== index) {
          tmpdelays.push(this.selecteddelaycodes[i]);
        }
      }
      this.selecteddelaycodes = tmpdelays;
      this.saveData();
    },
    saveData(showsuccessmsg = false) {
      const self = this;
      const api = new Apicall();
      self.success = null;
      self.error = null;
      if (this.$store.state.accessmode === 'FULLACCESS' || new Date(this.segmentinfos.departure_datetime).getTime() >= new Date(new Date().setDate(new Date().getDate() - 1)).getTime()) {
        api.call('POST', 'api/flight/save-report-infos-for-segment', {
          report: this.flightreport,
          deniedpax: this.deniedpax,
          segmentid: this.$route.params.id,
          timezone: this.currentTimezone,
          selecteddelaycodes: this.selecteddelaycodes,
        }).then((response) => {
          if (response.status === 'OK') {
            if (showsuccessmsg) {
              self.success = 'Data saved successfully';
              window.scrollTo(0, 0);
              window.setTimeout(() => { self.success = null; }, 5000);
            }
          } else {
            self.error = response.msg;
          }
        });
      }
    },
  },
};
</script>
