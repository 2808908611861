<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>Import Log</h1>
            <mdb-row>
              <mdb-col md="12">
                <mdb-tbl>
                  <mdb-tbl-head>
                    <tr>
                      <th>Date</th>
                      <th>User</th>
                    </tr>
                  </mdb-tbl-head>
                  <mdb-tbl-body>
                    <tr v-for="(log, index) in logs" v-bind:key="index">
                      <td>
                        {{formatDate(log.created_at)}}
                      </td>
                      <td v-if="log.user">
                        {{log.user.name}}
                      </td>
                      <td v-else>
                        -- UNKNOWN --
                      </td>
                    </tr>
                    <tr v-if="!logs.length">
                      <td colspan="2">No log found</td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

// on utilise ça : https://fullcalendar.io/docs/vue

export default {
  name: 'allflightslist',
  data() {
    return {
      error: null,
      logs: [],
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },
  mounted() {
    this.loadlog();
  },
  methods: {
    loadlog() {
      this.error = null;
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/flight/get-manifest-import-log', { flightid: this.$route.params.id }).then((response) => {
        self.logs = response.data;
      });
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    gotoflightdetails(flightid) {
      this.$router.push({ name: 'FlightDetails', params: { id: flightid } });
    },
  },
};
</script>
